<template>
  <div :class="['select-custom', { error: isValidate }]">
    <label v-if="title">
      {{ title }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <br v-if="title" />
    <b-form-select
      v-bind:options="options"
      :name="name"
      :size="size"
      v-bind:value="value"
      :required="required"
      :class="['btn-select']"
      @change="onDataChange"
      :valueField="valueField"
      :textField="textField"
      ref="input"
      :disabled="isDisplay"
      class="f-regular"
    ></b-form-select>

    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">{{
        options[0][textField || "text"]
      }}</span>
      <span class="text-error" v-else-if="v.minValue == false">{{
        options[0][textField || "text"]
      }}</span>
      <span class="text-error" v-if="v.valueDefault == false">{{
        options[0][textField || "text"]
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String
    },
    text: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    name: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    options: {
      required: true,
      type: Array
    },
    value: {
      required: false,
      type: [Number, String]
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    valueField: {
      required: false,
      type: String
    },
    textField: {
      required: false,
      type: String
    },
    isDisplay: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      selected: 0
    };
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event);
    },
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.select-custom {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.select-custom.error > .btn-select {
  border-color: red !important;
}
.select-custom > label {
  color: #575757;

  font-size: 14px;
  margin-bottom: 2px;
}
::v-deep .btn-select {
  color: #575757;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
}
::v-deep .btn-select.custom-select-lg {
  height: 45px;
  font-size: 14px;
}
::v-deep .btn-select:focus {
  border-color: #575757;
}
::v-deep .btn-select > option {
  background-color: white;
  color: #575757;
}
::v-deep .btn-select > option:checked {
  background-color: #575757 !important;
  color: white !important;
}
::v-deep .btn-select:focus {
  box-shadow: unset !important;
}
::v-deep .btn-select:disabled {
  color: #6c757d !important;
  background-color: #e9ecef !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .select-custom {
    margin-top: 0;
  }
  .select-custom > label {
    font-size: 15px;
  }
}
</style>
