<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      @hidden="hide"
    >
      <div class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <div class="text-center">
        <font-awesome-icon :icon="['fas', 'check']" class="" />
        <p class="text-text" v-if="text">{{ text }}</p>
        <p class="textSecondLine" v-if="textSecondLine">{{ textSecondLine }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    },
    textSecondLine: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.$emit("show");
    },
    hide() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="stylesheet" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  font-weight: bold;
  color: #16274a;
  margin-bottom: 0;
  font-size: 20px;
}
.textSecondLine {
  color: #16274a;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 16px !important;
}
.modal-body {
  margin: auto;
  text-align: center;
}
svg {
  width: 91px;
  height: 91px;
  font-size: 50px;
}
@media (max-width: 767.98px) {
  .text-text {
    font-size: 15px;
  }
}
</style>
