<template>
  <b-modal
    id="modal-unique"
    v-model="isModalShow"
    hide-header
    hide-footer
    class=""
  >
    <div><span class="text-big">แก้ไขรายการสำเร็จ</span></div>
    <div><span class="text-small">คัดลอกลิงค์สำหรับลูกค้าสำเร็จ</span></div>
    <div>
      <u style="color: blue" class="pointer" @click="goToClientPage()">{{
        urlLink
      }}</u>
    </div>

    <div>
      <input id="testing-copy-link" type="hidden" :value="urlLink" />
      <b-button
        class="m-2 bg-color-primary"
        pill
        id="copy-link"
        @click="copyTestingCode(urlLink)"
        >คัดลอกลิงค์</b-button
      >
    </div>
    <div v-if="remarkMessage" class="my-2">
      <span class="text-danger f-size-14">*** {{ remarkMessage }}</span>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "CopyLink",
  props: {
    urlLink: {
      type: String
    },
    remarkMessage: {
      type: String
    }
  },
  data() {
    return {
      filter: {
        page: 1,
        search: "",
        take: 20
      },
      address: null,
      isModalShow: false
    };
  },

  methods: {
    show() {
      this.isModalShow = true;
    },
    hide() {
      this.isModalShow = false;
    },
    goToClientPage() {
      window.open(this.urlLink, "_blank");
    },
    setShowModalCopyLink(value) {
      this.$emit("setShowModalCopyLink", value);
    },
    async copyTestingCode() {
      var text = document.getElementById("testing-copy-link").value;

      navigator.clipboard
        .writeText(text)
        .then(() => {})
        .catch(err => {
          console.error("Error in copying text: ", err);
        });
    }
  }
};
</script>

<style lang="scss">
.f-size-14 {
  font-size: 14px;
}
#modal-unique .modal-body {
  text-align: center;
  margin-top: 40px;
}
#copy-link {
  background-color: black;
  color: white;
}
#modal-unique .modal-content {
  height: 100%;
}
</style>
