<template>
  <div>
    <b-modal
      v-model="modalShow"
      title="ปฏิเสธการชำระเงิน"
      hide-footer
      header-class=""
    >
      <div>
        <div>
          <b-form-group
            label="เหตุผลในการปฏิเสธการชำระเงิน"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              v-model="form.ReasonId"
              :options="reasonList"
              :aria-describedby="ariaDescribedby"
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
              stacked
              class="ml-3 mb-0"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="pl-5 mb-3" v-if="form.ReasonId == 0">
          <b-form-textarea
            id="textarea"
            v-model="form.Note"
            placeholder=""
            rows="3"
            max-rows="6"
            @keyup="isError = false"
          ></b-form-textarea>
          <span class="text-error f-size-14" v-if="isError"
            >กรุณาระบุรายละเอียดเพิ่มเติม</span
          >
        </div>
        <div class="d-flex justify-content-end mt-4">
          <div>
            <b-button class="btn-main-2" @click="hide">ยกเลิก</b-button>
          </div>
          <div class="ml-2">
            <b-button class="btn-main" @click="onCheckform">ตกลง</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hide="hadleModalAlertClose"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
export default {
  components: {
    ModalAlert,
    ModalAlertError,
    ModalLoading
  },
  props: {},
  data() {
    return {
      modalShow: false,
      form: {
        ReasonId: 0,
        Note: ""
      },
      modalMessage: "",
      isDisable: false,
      reasonList: [],
      isError: false
    };
  },
  validations: {},
  methods: {
    onCheckform() {
      if (this.form.ReasonId == 0 && !this.form.Note) {
        this.isError = true;
        return;
      }
      this.$emit("submitRejectPayment", this.form);
    },
    hadleModalAlertClose() {},
    getRejectResonList: async function() {
      await this.$axios
        .get(`${this.$baseUrl}/api/transaction/rejectInfo`)
        .then(data => {
          this.reasonList = data.data.detail.reasonList;
          this.reasonList.push({ id: 0, name: "อื่นๆ" });
          this.form.ReasonId = data.data.detail.reasonList[0].id;
        });
    },
    show: async function() {
      this.isError = false;
      this.form = {
        ReasonId: 0,
        Note: ""
      };
      await this.getRejectResonList();
      this.modalShow = true;
    },
    hide() {
      this.modalShow = false;
    }
  }
};
</script>

<style scoped>
.text-error {
  color: red;
}
</style>
